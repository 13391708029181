/* You can add global styles to this file, and also import other style files */
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;1,200&display=swap');

* {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  size: 20px;
  line-height: 28.04px;
}

.card-roll.p-card .p-card-body  {
  padding: 0.25rem !important;
  margin:  0 !important;
}


.sidebar ul li a:hover {
  color: var(--black-color);
  background: var(--white-color);
}

.cursor {
  cursor: pointer;
}

.p-scrollpanel .p-scrollpanel-bar {
  background-color: #000 !important;
}

.td {
  font-size: 0.8em !important;
}

.p-datatable .p-datatable-thead tr th {
  background-color: #c1bfc4 !important;
  font-size: 0.8em !important;
}

.p-dialog .p-dialog-header {
  background-color: #fff !important;
  /* Fondo negro */
  opacity: 0.9 !important;
  /* Transparencia del elemento en sí */
}

.p-dialog .p-dialog-content {
  background-color: #fff !important;
  /* Fondo negro */
  opacity: 0.9 !important;
  /* Transparencia del elemento en sí */
}

/* confirm pop up weaving */
.p-button.button-confirm-programmer-machine-weaving {
  background-color: #FFE2D4 !important;
  color: #000000 !important;
}

.p-button.button-reject-programmer-machine-weaving {
  background-color: #FFE2D4 !important;
  color: #000000 !important;
}

/* Estilo para ajustar la altura del p-dropdown */
.p-dropdown.height .p-dropdown-label {
  font-size: 12px; /* Tamaño de fuente ajustado */
  height: 10px; /* Altura ajustada */
  line-height: 5px; /* Ajustar el line-height para centrar el texto verticalmente */
}

.p-inputtext.height {
  height: 20px !important; 
}

.p-button.p-button-sm.padding-0 {
  margin: 0 !important;
  padding: 0 !important
}

.p-button.p-button-sm.padding-1 {
  margin: 0 !important;
  padding: 0.15rem !important
}

.p-button.p-button-sm.padding-2 {
  margin: 0 !important;
  padding: 0.30rem !important
}
.p-button.p-button-sm.padding-3 {
  margin: 0 !important;
  padding: 0.50rem !important
}

.p-button.p-button-sm.padding-4 {
  margin: 0 !important;
  padding: 0.75rem !important
}
.p-button.p-button-sm.padding-5 {
  margin: 0 !important;
  padding: 1rem !important
}

.p-button.p-button-lg.padding-lg-0 {
  margin: 0 !important;
  padding: 0 !important
}

.p-button.p-button-lg.padding-lg-1 {
  margin: 0 !important;
  padding: 0.15rem !important
}

.p-button.p-button-lg.padding-lg-2 {
  margin: 0 !important;
  padding: 0.30rem !important
}
.p-button.p-button-lg.padding-lg-3 {
  margin: 0 !important;
  padding: 0.50rem !important
}

.p-button.p-button-lg.padding-lg-4 {
  margin: 0 !important;
  padding: 0.75rem !important
}
.p-button.p-button-lg.padding-lg-5 {
  margin: 0 !important;
  padding: 1rem !important
}

.icon-width-1.p-button.p-button-icon-only {
  width: 1rem !important;
}
.icon-width-2.p-button.p-button-icon-only {
  width: 2rem !important;
}
.icon-width-3.p-button.p-button-icon-only {
  width: 3rem !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon{
  height: 0.6rem !important;
}

.p-button.p-button-icon-only{
  /* width: 1rem !important; */
}

.p-button.p-button-danger.p-button-outlined{
  border : 0 !important
}
  
/* confirm pop up weaving */

/* Menu */

.p-sidebar-left.p-sidebar-sm,
.p-sidebar-right.p-sidebar-sm {
  width: 10rem !important;
  background-color: #111;
}

.background-menu {
  background-color: #596260 !important;
}

.fs-icon {
  font-size: 1.5em !important;
}

.tab-weaving .p-tabview .p-tabview-panels {
  padding: 0 !important;
}

.p-accordion p-accordiontab .p-accordion-tab{
  padding: 0 !important;
  margin: 0 !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link{
  padding: 0 !important;
}

.p-accordion .p-accordion-content{
  margin-bottom: 0px !important;
  padding: 0 !important;
  margin: 0 !important;
  /* height: 2.5rem !important; */
}

.p-message .p-message-wrapper{
  padding: 0 1rem !important;
}

/* Menu */

/* Login */
p-button .button-login {
  background-color: #c9405e !important;
  border-radius: 40px !important;
  color: #000e0b !important;
}

p-button .button-login :enabled:hover {
  background-color: #ca2039;
}

/* Login */

/* Assign-order-modal */
p-button .assing-deposit-button {
  background-color: #F6CACA !important;
  color: #000 !important;
}

/* Assign-order-modal */

/* Assign-order-modal-note */
p-button .assing-deposit-button {
  background-color: #F6CACA !important;
  color: #000 !important;
}

/* Assign-order-modal-note */

/* date-promise-modal */
.date-promise-button {
  background-color: #F6CACA !important;
  color: #000 !important;
}

/* date-promise-modal */

/* Tintoteria - 1 */
p-button .add-order-cleaning {
  background-color: #C0AEC3 !important;
  color: #000000 !important;
}

p-button .select-order-cleaning {
  background-color: #C6C5FF !important;
  color: #000000 !important;
}

/* Tintoteria - 1  */

/* Add order */

.title {
  color: #000e0b;
}

p-button .save-order-cleaning {
  background-color: #C6C5FF !important;
  color: #000e0b !important;
}

.input-form {
  border: 3px solid #ca2039 !important;
}

/* Add order */

/* assing-machine */
p-button .button-add-divide-cleaning:enabled:hover {
  background-color: #C6C5FF;
}

p-button .button-add-divide-cleaning {
  background-color: unset;
  color: #000e0b !important;
  border: 1px solid #000e0b !important;
}

p-button .save-order-machine-cleaning {
  background-color: #C6C5FF !important;
  color: #000e0b !important;
}

p-dropdown .p-dropdown.p-dropdown-clearable.dropdown-machine-divide {
  border: 3px solid #ca2039 !important;
}

/* assing-machine */

/* table-production-cleaning-planning-order */
.button-icon-assign-machine .p-button-icon {
  font-size: 1.5rem !important;
  color: #000 !important;
}

p-button .button-programmer-machine .p-button-icon {
  color: #000 !important;
}

p-button .button-programmer-machine {
  background-color: #c6c5ff !important;
  color: #000 !important;
}

p-button .button-icon-assign-machine {
  background-color: unset !important;
  border: none !important;
}

/* table-production-cleaning-planning-order */

/* Tejeduria - 1 */
p-button .add-order-weaving {
  background-color: #F6CACA !important;
  color: #000000 !important;
}

p-button .select-order-weaving {
  background-color: #FFE2D4 !important;
  color: #000000 !important;
}

/* Tejeduria - 1 */

/* Tejeduria - planning-order */

p-button .programmer-machine-weaving {
  background-color: #FFE2D4 !important;
  color: #000000 !important;
}




/* Tejeduria - planning-order */

/* add quantity weaving */

p-button .save-order-weaving {
  background-color: #F6CACA !important;
  color: #000e0b !important;
}

/* add quantity weaving */

/* table-production-weaving-planning-order */
.button-icon-weaving-order .p-button-icon {
  font-size: 1.5rem !important;
  color: #000 !important;
}

p-button .button-icon-weaving-order {
  background-color: unset !important;
  border: none !important;
}

/* table-production-weaving-planning-order */

/* assign-raw */

p-button .save-remito {
  background-color: #FFE2D4 !important;
  border-radius: 40px !important;
  color: #000 !important;
}

p-button .button-add-remito {
  background-color: unset;
  color: #000e0b;
}

p-button .button-add-remito:enabled:hover {
  background-color: #ca2039;
}

p-button .button-add-remito-cleaning:enabled:hover {
  background-color: #FFE2D4;
}

p-button .button-add-remito-cleaning {
  background-color: unset;
  color: #000e0b !important;
  border: 1px solid #000e0b !important;
}

/* assign-raw */

/* redo-process-cleaning */

p-button .redo-process-button {
  background-color: #FFE2D4 !important;
  color: #000 !important;
}

/* redo-process-cleaning */

/* finalize-process-cleaning */

p-button .finalize-process-button {
  background-color: #FFE2D4 !important;
  color: #000 !important;
}

/* finalize-process-cleaning */


/* upload */
.custom-file-upload.p-fileupload .p-fileupload-buttonbar .p-button {
  width: 90% !important;
  padding: 0 !important;
}

.custom-file-upload .p-fileupload-buttonbar {
  border: 0 !important;
  padding: 0 !important;
  background-color: #fff !important;
}

.custom-file-upload.p-fileupload .p-fileupload-content {
  padding: 0 !important;
  border: 0 !important;
  background-color: #fff !important;
}

.custom-file-upload .p-fileupload-choose:not(.p-disabled):hover {
  background-color: #c79886;
}

.custom-file-upload .p-fileupload-content .p-progressbar {
  width: 0 !important;
}

.custom-file-upload .p-button-label {
  text-align: start;
  color: #ccc;
}

.custom-file-upload .p-button {
  background-color: #fff;
}

p-button .p-button-remove-upload {
  background-color: #000 !important;
}

p-button .button-upload:enabled:hover {
  background-color: #c79886 !important;
}

p-button .button-upload .p-button-label {
  color: #000 !important;
}


p-button .button-upload {
  background-color: #FFE2D4 !important;
}

.p-button.button.upload.p-button-icon-only {
  background-color: #000 !important;
}

/* upload */

/* urdido */

p-button .button-urdido {
  background-color: #F4D37E !important;
  border-radius: 40px !important;
  color: #000 !important;
}

button-upload

/* urdido */

/* warped - allocate raw material */

p-button .button-assign-mp {
  background-color: #F4D37E !important;
  border-radius: 40px !important;
  color: #000 !important;
}


/* add-order-warped */
p-button .add-order-warped {
  background-color: #F0F47E !important;
  border-radius: 40px !important;
  color: #000 !important;
}

p-button .save-order-warped {
  background-color: #F6CACA !important;
  border-radius: 40px !important;
  color: #000 !important;
}

/* add-order-warped */






/* urdido */


/* abm - fabric form */

.button-add-process{
    height: 5px !important;
    width: 30px !important;
}

p-button .button-add-process:enabled:hover {
  background-color: #C6C5FF;
}

p-button .button-add-process {
  background-color: unset;
  color: #000e0b !important;
  border: 1px solid #000e0b !important;
}

/* abm - fabric form */



.scroll-panels{
  /* padding-left: 0 !important; */
  /* padding-top: 0 !important; */
  /* padding-bottom: 0 !important; */
  padding-right: 0 !important;
  margin: 0 !important;
}